// OSCAR CHECKOUT UNIQUE STYLES
// -----------

//Set max width of all quantity fields
input[type="text"][id*="quantity"], input[type="number"][id*="quantity"] {
    max-width: 55px;
}

.checkout-quantity .input-group {
  max-width: 130px;
  input[type="number"][id*="quantity"] {
    max-width: none;
  }
}

// Quantity on the basket
.checkout-quantity {
    input {
        margin-right: 2px;
        margin-bottom:0px;
    }
}

// Basket items like table headers
.basket-title {
    clear:both;
    border-bottom:1px solid lighten(@gray-light, 30%);
    padding:0 10px;
    h4 {
        margin:0;
    }
}

// Basket items
.basket-items {
    border-bottom:1px solid lighten(@gray-light, 30%);
    padding:10px;
    margin-bottom:20px;
    &:nth-child(even) {
        background:lighten(@gray-lighter, 4%);
    }
    img {
        margin-left:auto;
        margin-right:auto;
    }
    .price_color {
        .h4();
        color:@brand-success;
        font-weight:bold;
    }
}

// Descriptions next to shipping prices - basket totals table
#basket_totals {
    th small {
        font-weight: normal;
    }
}

// Table background for totals
.total {
    background-color:#FDF5D9!important;
}


// Choosing a shipping address
.choose-block {
    ul {
        .clearfix();
    }
    .well {
        position:relative;
        min-height:240px;
        form {
        margin-bottom:0;
        }
    }
    address {
        margin-bottom:0;
        span:first-child {
        display:block;
        font-weight:bold;
        margin-bottom:@line-height-computed / 2;
        }
    }
    .btn-primary,
    .btn-success {
        position:absolute;
        bottom: @grid-gutter-width;
        left: @grid-gutter-width;
    }
    .address-controls {
        position:absolute;
        top: @grid-gutter-width;
        right: @grid-gutter-width;
    }
}

// Checkout shipping address form
#new_shipping_address, #update_user_address {
    #id_title {
        width: auto;
    }
    #id_line1, #id_line2, #id_line3 {
        max-width: 300px;
    }
    #id_postcode {
        max-width: 100px;
    }
    #id_notes {
        max-width: 600px;
        height: 100px;
    }
}

// Checkout navigation - uses navbar
.nav-checkout {
    text-align:center;
    &.navbar .nav {
        margin:0;
    }
    .active {
        .navbar-text {
        color:#fff;
        }
    }
}
